@import '../../variables.scss';

#top-menu-wrapper {
  * {
    color: white;
  }
  img {
    max-height: 25px;
  }
  nav.navbar-top {
    padding: 0.8rem 1.25rem;
    background-color: $color-dark;
  }

  .nav-dropdown {
    .dropdown-menu {
      left: auto;
      right: 0;
      * {
        color: $color-dark;
      }
    }

    .dropdown-item {
      &:hover {
        background-color: $color-primary;
        color: white;
      }
    }
  }
}
