@import './variables.scss';

* {
  font-family: 'Open Sans', sans-serif;
  font-size: 14px;
}

::-webkit-scrollbar-track {
  border-radius: 10px;
  background-color: white;
}

::-webkit-scrollbar {
  width: 10px;
  height: 10px;
  border-radius: 10px;
  background-color: white;
}

::-webkit-scrollbar-thumb {
  border-radius: 10px;
  background-color: $color-dark;
}

#root {
  height: 100vh;
  background-color: $color-darkest;
}

#main-container {
  background-color: white;
  padding: 1rem;
}

h1 {
  font-size: 44px;
  line-height: 100%;
  font-weight: 900;
  font-style: normal;
}

h2 {
  font-size: 36px;
  line-height: 100%;
  font-weight: 900;
  font-style: normal;
}

h3 {
  font-size: 30px;
  line-height: 100%;
  font-weight: 900;
  font-style: normal;
}

h4 {
  font-size: 24px;
  line-height: 100%;
  font-weight: 900;
  font-style: normal;
}

h5 {
  font-size: 18px;
  line-height: 100%;
  font-weight: 700;
  font-style: normal;
}

h6 {
  font-size: 16px;
  line-height: 100%;
  font-weight: 700;
  font-style: normal;
}

.flex-scrollable-container {
  display: flex;
  flex-direction: column;
}

.link {
  color: $color-primary;
  font-weight: bold;
  text-align: center;
}

.page-header {
  color: $color-primary;
  font-weight: bolder;
  font-size: 18px;
}

.text-small {
  font-size: 11px;
  * {
    font-size: 11px;
  }
}

.text-lighter {
  color: $color-middle;
  * {
    color: $color-middle;
  }
}

.text-darker {
  color: $color-dark;
  * {
    color: $color-dark;
  }
}

.background-lightest {
  background-color: $color-lightest;
}

.background-darkest {
  background-color: $color-darkest;
}

.form-control {
  background-color: white !important;
  border: 1px solid $color-dark !important;
  box-shadow: none;
  font-size: 12px !important;
  color: $color-darkest !important;

  &:read-only {
    background-color: white !important;
    border-color: $color-middle !important;
    color: $color-dark !important;
  }

  &:focus {
    box-shadow: 0 0 0.25rem $color-dark !important;

    &:read-only {
      box-shadow: none !important;
    }
  }

  &.highlighted {
    border-color: $color-primary !important;
  }
}

.form-select {
  background-color: white !important;
  border: 1px solid $color-dark !important;
  box-shadow: none;
  font-size: 12px !important;
  color: $color-darkest !important;

  &:disabled {
    background-color: white !important;
    border-color: $color-middle !important;
    color: $color-dark !important;
  }

  &:focus {
    box-shadow: 0 0 0.25rem $color-dark !important;

    &:read-only {
      box-shadow: none !important;
    }
  }

  &.highlighted {
    border-color: $color-primary !important;
  }
}

.form-check-label {
  font-size: 12px !important;
  color: $color-darkest !important;
}

.form-check-input:checked {
  background-color: $color-primary !important;
  border-color: $color-primary !important;
}

.form-check-input {
  border-color: $color-dark !important;

  &:focus {
    border-color: $color-dark !important;
    box-shadow: none !important;
  }
}

.form-floating {
  label {
    color: $color-darkest;
  }
}

.btn {
  min-width: 7rem;
}

#images-viewer-wrapper {
  .gallery {
    display: flex;
    flex-wrap: wrap;
  }
  .image-container {
    width: 50%;
    padding-left: 1rem;
    padding-right: 1rem;
    padding-bottom: 2rem;
    transition: all 0.3s ease-in-out;
    img {
      cursor: pointer;
      width: 100%;
      border-radius: 0.25rem;
      box-shadow: 0px 0px 0.5rem $color-light;
    }
    &:hover {
      transform: scale(1.05);
    }
  }
}

.btn.btn-primary {
  background-color: $color-primary;
  color: white;
  border-color: $color-primary;

  &:hover {
    background-color: $color-primary-dark;
    border-color: $color-primary-dark;
  }
  &:focus {
    background-color: $color-primary-dark;
    border-color: $color-primary-dark;
    box-shadow: none;
  }
}

.attachments {
  display: flex;
  flex-wrap: wrap;
  .attachment-container {
    padding: 1rem;
    margin: 0.5rem;
    background-color: $color-dark;
    color: white;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;

    svg {
      font-size: 2rem;
      margin-bottom: 0.5rem;
    }
  }
}

.tox-notifications-container {
  display: none !important;
}

.tox-tinymce {
  border: 1px solid $color-dark !important;
  border-radius: 0.25rem !important;
  height: 100% !important;
}

.nav-tabs {
  .nav-link {
    color: $color-primary;
    &:hover {
      color: $color-primary;
    }
  }
}

.filter {
  width: auto;

  &.form-control {
    width: auto;
  }
}

.success {
  color: $color-success-saturated;
}

.danger {
  color: $color-danger-saturated;
}

#ReactSimpleImageViewer {
  span {
    opacity: 0.7;
  }
}
