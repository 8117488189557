@import '../../variables.scss';

#toast-notification-wrapper {
  .top-container {
    z-index: 10000;
  }

  .bottom-container {
    z-index: 10000;
  }

  .toast {
    box-shadow: 0px 0px 1rem #888888;
    z-index: 10000;
    width: 100vw;
    color: white;

    .button-close {
      padding: 0px;
      background-color: transparent;
      border: none;
    }

    .toast-header {
      background-color: transparent;
      border: none;
    }

    &.bg-success {
      background-color: $color-success-saturated !important;
    }
    &.bg-warning {
      background-color: $color-warning-saturated !important;
    }
    &.bg-danger {
      background-color: $color-danger-saturated !important;
    }
  }

  .fade-top {
    animation: slideAndFadeTop 0.5s linear;
  }

  .fade-bottom {
    animation: slideAndFadeBottom 0.5s linear;
  }

  @keyframes slideAndFadeTop {
    0% {
      opacity: 0;
      transform: translateY(200%);
    }
    100% {
      opacity: 1;
      transform: translateY(0%);
    }
  }

  @keyframes slideAndFadeBottom {
    0% {
      opacity: 0;
      transform: translateY(-200%);
    }
    100% {
      opacity: 1;
      transform: translateY(0%);
    }
  }

  @media screen and (min-width: $desktop-breakpoint) {
    .top-container {
      z-index: 10000;
      width: 100vw;
      padding: 2rem;
    }

    .bottom-container {
      z-index: 10000;
      width: 100vw;
      padding: 2rem;
    }

    .toast-wrapper {
      display: flex;
      justify-content: flex-end;
    }

    .toast {
      max-width: 400px;
    }
  }
}
