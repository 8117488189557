@import '../../variables.scss';

#bnf-table-wrapper {
  background-color: white;
  overflow: auto;

  * {
    font-size: 12px;
  }

  table {
    background-color: white;
    white-space: nowrap;
    border: none;
    * {
      border: none;
    }

    thead {
      color: $color-dark;
      border-bottom: 1px solid $color-middle;
    }

    th {
      font-weight: normal;
      margin-top: 0px;
      padding-top: 0px;
    }
  }

  table > tbody > tr {
    border-bottom: 1px solid $color-light;
  }

  .pagination {
    background-color: transparent;
    .btn {
      min-width: 40px;
      padding: 5px;
    }
  }
}
