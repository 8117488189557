$desktop-breakpoint: 992px;

$color-lightest: #e8eaed;
$color-light: #d1d5db;
$color-middle: #acb3be;
$color-dark: #5e6878;
$color-darkest: #2f343c;

$color-blue: #1e40af;
$color-blue-dark: #1e3a8a;

$color-yellow: #ffbf00;
$color-yellow-dark: #805f00;

$color-primary: $color-yellow;
$color-primary-dark: $color-yellow-dark;

$color-success: #d4edda;
$color-success-saturated: #28a745;
$color-danger: #f8d7da;
$color-danger-saturated: #dc3545;
$color-warning: #fff3cd;
$color-warning-saturated: #ffc107;
