@import '../../variables.scss';

#loading-wrapper {
  .loading {
    background-color: transparent;
    position: absolute;
    display: flex;
    justify-content: center;
    align-items: center;
    width: 100%;
    height: 100%;
    top: 0;
    left: 0;
    font-size: 1rem;
    z-index: 10000000;

    .spinner-border {
      width: 3rem;
      height: 3rem;
      color: $color-primary;
      font-size: 20px;
    }
  }

  h6 {
    font-weight: lighter;
  }

  .spinner-frame {
    position: relative;
    background-color: white;
    border-radius: 100%;
    padding: 0.7rem;
    box-shadow: 0px 0px 0.3rem gray;
  }

  .animate-loading {
    animation: slideFromTop 0.7s ease-in-out;
  }

  @keyframes slideFromTop {
    0% {
      opacity: 0;
      top: -200px;
    }
    100% {
      opacity: 1;
      top: 0;
    }
  }
}
