@import '../../variables.scss';

#side-menu-wrapper {
  background-color: $color-darkest;
  height: 100%;
  width: 15rem;
  transition: width 0.5s;

  &.condensed {
    width: 5rem;
    .nav-condense {
      justify-content: center;
    }
    .nav-item {
      justify-content: center;
    }
    .nav-label {
      display: none;
    }
    .nav-icon {
      margin-right: 0px;
    }
    .condense-icon {
      transform: rotate(180deg);
    }
  }

  .nav-item {
    padding: 0.8rem 1.25rem;
    text-decoration: none;
    display: flex;
    align-items: center;
    width: 100%;

    &.active {
      color: white;
      background-color: $color-primary;
    }
    &:hover {
      background-color: $color-primary;
    }
  }

  .nav-icon {
    color: white;
    width: 1.5rem;
    height: 1.5rem;
    margin-right: 1rem;
    display: flex;
    align-items: center;
    justify-content: center;
  }

  .nav-label {
    color: white;
    font-size: 1rem;
  }

  .nav-item-header-btn {
    width: 100%;
    outline: none;
    border: none;
    background: transparent;
    cursor: pointer;
  }

  .nav-chevron {
    color: white;
    width: 1.5rem;
    height: 1.5rem;
    margin-left: auto;
    transition: all 0.25s;

    &.expanded {
      transform: rotate(180deg);
    }
  }

  .nav-children {
    background-color: $color-primary;
  }

  .nav-condense {
    padding: 0.8rem 1.25rem;
    text-decoration: none;
    display: flex;
    align-items: center;
    justify-content: flex-end;
  }

  .condense-icon {
    color: white;
    width: 1.5rem;
    height: 1.5rem;
    transition: all 0.25s;
    display: flex;
    align-items: center;
    justify-content: center;
  }
}
